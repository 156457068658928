import user from '../user';

/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @returns {Object} { Authorization }
 */
export default function getAuthorizationHeader({ requestOptions } = {}) {
  const headers = {};

  // Check for OHIF.user since this can also be run on the server
  const accessToken = user && user.getAccessToken && user.getAccessToken();

  // Auth for a specific server
  if (requestOptions && requestOptions.auth) {
    if (typeof requestOptions.auth === 'function') {
      // Custom Auth Header
      headers.Authorization = requestOptions.auth(requestOptions);
    } else {
      // HTTP Basic Auth (user:password)
      headers.Authorization = `Basic ${btoa(requestOptions.auth)}`;
    }
  }
  // Auth for the user's default
  else if (accessToken) {

    headers.Authorization = `Bearer ${accessToken}`;
  }

  const private_token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijk2NzVkYzBiNWI3OWE5Mzc1NTEyOTk2ODk4MTJlYThiNWViYTBmY2IifQ.eyJpc3MiOiJtZWRkcmVhbS1zZXJ2aWNlQHJlbW90ZS1tZWRpY2FsLTMxMzExOS5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImlhdCI6MTYzOTE5MDIxMCwic3ViIjoibWVkZHJlYW0tc2VydmljZUByZW1vdGUtbWVkaWNhbC0zMTMxMTkuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJleHAiOjE2MzkxOTM4MTAsImF1ZCI6Imh0dHBzOi8vaGVhbHRoY2FyZS5nb29nbGVhcGlzLmNvbS8ifQ.t5JXuGhLpKBSWEOyuO6hZHMKLaRI8VSpcr5Xs-QAU74ZVJBOwPiLkmLDXyx-V8a-osTucXj1hzr_CL_NlqHzKxrtz5d2Obk50FsHRMkBeGei4Q3Q5tRaC4MzGIt8uMfPNKgNEnh0LmCIDSobjW5-gi1b2SdS-Lw_qiKm04Df4DG79-aK1dFVzabhrskLpmMyPOsYZLbhkYtpqXDyOFobeGWJrEcgeY5f1yb6x3Umpq_-aN1jAPjkw_JNVj--O7ZW2mTcidwg1xw6kMsasCbHLHcf7uYB2o_jfyAjJvn1AlVxTh06kS43VXRx_Mz4iiayVAXyNCFYcMLS2rtwOF6AMw";

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  //headers.Authorization = `Bearer ${private_token}`;

  return headers;
}
