const jsutil = {

};

jsutil.test1 = function() {
    console.log("TEST SUCCESS HI THERE");
}

jsutil.xmlhttprequest = async function(requestTypeStr, urlStr, postObj = null, contentTypeStr = "json") {
    if (contentTypeStr !== "json") {
        throw "Unsupported shortcut xmlhttlrequest content type";
    }

    let errorRet = null;
    let wasError = false;
    let requestObj = new XMLHttpRequest();
    let sendObj = null;
    let responseObj = null;
    let responseStatus = 0;
    let wasCompleted = false;

    let promiseRes = null;
    const p = new Promise((r) => promiseRes = r);

    requestObj.open(requestTypeStr, urlStr);
    if (contentTypeStr === "json") {
        requestObj.setRequestHeader("Content-Type", "application/json");
    }

    if (postObj != null) {
        if (typeof postObj === "string") {
            sendObj = postObj;
        } else {
            try {
                sendObj = JSON.stringify(postObj);
            } catch (e) {
                throw "Failed to JSON.stringify post data";
            }
        }
    }

    requestObj.onerror = function(e) {
        wasError = true;
        if (typeof e !== "undefined") {
            errorRet = e;
        }
    }
    requestObj.onreadystatechange = function() {
        if (requestObj.readyState === XMLHttpRequest.DONE) {
            responseStatus = requestObj.status;
            try {
                if (contentTypeStr === "json") {
                    responseObj = JSON.parse(requestObj.responseText);
                }
            } catch(e) {
                wasError = true;
            }

            wasCompleted = true;

            promiseRes({
                error: wasError,
                responseObj: responseObj,
                responseStatus: responseStatus
            });
        }
    }

    if (sendObj != null) {
        requestObj.send(sendObj);
    } else {
        requestObj.send();
    }

    return p;
}

export default jsutil;