import './RetiVueLogo.css';

import { Icon } from '@ohif/ui';
import React from 'react';

function RetiVueLogo() {
  return (
    <a
      rel="noopener noreferrer"
      className="header-brand"
    >
      <Icon name="retivue-logo" className="header-logo-image" />
      {/* Logo text would fit smaller displays at two lines:
       *
       * Open Health
       * Imaging Foundation
       *
       * Or as `OHIF` on really small displays
       */}
      <Icon name="retivue-text-logo" className="header-logo-text" />
    </a>
  );
}

export default RetiVueLogo;
