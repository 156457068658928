import RetiVueLogo from '../components/RetiVueLogo/RetiVueLogo.js';
import React from 'react';

const defaultContextValues = {
  createLogoComponentFn: React => RetiVueLogo(),
};

const WhiteLabelingContext = React.createContext(defaultContextValues);

export default WhiteLabelingContext;
