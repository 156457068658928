import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import detect from 'browser-detect';
import { useTranslation } from 'react-i18next';
import { redux } from '@ohif/core';
import api from "../../../../../viewer/src/googleCloud/api/GoogleCloudApi";
import { Icon } from "@ohif/ui";

import './LoginContent.styl';
import { servicesManager, recoilRetivueLoggedIn, recoilRetivueStores, recoilRetivueStoreIndex } from '../../../../../viewer/src/App.js';
import user from '../../../../../core/src/user.js';
import * as GoogleCloudUtilServers from '../../../../../viewer/src/googleCloud/utils/getServers';
import AppContext from '../../../../../viewer/src/context/AppContext';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import jsutil from '../../../../../viewer/src/jsutil/jsutil'

const LoginContent = () => {
  const { UIModalService } = servicesManager.services;
  const { setServers } = redux.actions;

  const [isLoggedIn, setIsLoggedIn] = useRecoilState(recoilRetivueLoggedIn);
  const [dicomStores, setDicomStores] = useRecoilState(recoilRetivueStores);
  const [storeIndex, setStoreIndex] = useRecoilState(recoilRetivueStoreIndex);
  const refStoreIndex = useRef(storeIndex);

  const [loginFailed, setLoginFailed] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  useEffect(function() {
    refStoreIndex.current = storeIndex;
  }, [storeIndex]);

  const capitalize = s =>
    s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

  const itemsPreset = () => {
    return [
      {
        name: 'RetiVue Website',
        value: 'https://github.com/OHIF/Viewers/',
        link: 'https://github.com/OHIF/Viewers/',
      },
      {
        name: 'Latest Master Commits',
        value: 'https://github.com/OHIF/Viewers/commits/master',
        link: 'https://github.com/OHIF/Viewers/commits/master',
      },
      {
        name: 'Version Number',
        value: "vnum",
      },
      {
        name: 'Build Number',
        value: "bnum",
      },
      {
        name: 'Browser',
        value: "brows",
      },
      {
        name: 'OS',
        value: "theos",
      },
    ];
  };

  let enteredToken = "";
  let enteredPassword = "";
  let enteredAuthy = "";
  let savedAccessToken = "";

  const onFormKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmitClicked();
    }
  };

  const onTokenEdit = (value) => {
      enteredToken = value;
  };

  const onPasswordEdit = (value) => {
    enteredPassword = value;
  };

  const onAuthyEdit = (value) => {
    enteredAuthy = value;
  };

  const onForgotClicked = () => {
    setResettingPassword(true);
  };

  const onEmailKeyPress = (e) => {
    if (e.key === "Enter") {
      onEmailClicked();
    }
  };

  const onEmailClicked = () => {
    setIsCheckingEmail(true);

    enteredToken = document.getElementById("emailField").value;
    const auth = getAuth();
    sendPasswordResetEmail(auth, enteredToken).then(() => {
      setIsCheckingEmail(false);
      setSentEmail(true);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      setIsCheckingEmail(false);

      if (errorCode === "auth/invalid-email" || errorCode === "auth/missing-email") {
        setInvalidEmail(true);
      } else {
        setSentEmail(true);
      }
    });
  };

  const onCancelClicked = () => {
    setIsCheckingEmail(false);
    setResettingPassword(false);
    setSentEmail(false);
    setInvalidEmail(false);
    setLoginFailed(false);
  };

  const onSubmitClicked = async () => {
    //setLoginFailed(true);
    enteredToken = document.getElementById("emailField").value;
    enteredPassword = document.getElementById("passwordField").value;

    if (enteredToken.length === 0) {
      setLoginFailed(true);
      setIsLoggedIn(false);
      return;
    }

    setIsLoggingIn(true);
    const auth = getAuth();
    let userCredential = null;
    let fbFailed = false;
    try {
      userCredential = await signInWithEmailAndPassword(auth, enteredToken, enteredPassword);
    } catch (e) {
      console.log(e);
      fbFailed = true;
    }

    // Signed in
    let loggedUser = null;
    let postObj = null;
    let fbResponse = null;
    if (!fbFailed) {
      loggedUser = userCredential.user;
      postObj = {
        user_token: loggedUser.accessToken,
        user_uid: loggedUser.uid,
        authy_token: enteredAuthy,
        authy_session_token: window.authy_session_token
      };
      window.loggedUser = loggedUser;

      fbResponse = await jsutil.xmlhttprequest("POST", window.config.firebase.tokenGenerator, postObj);
    }

    if (fbFailed || fbResponse.error || fbResponse.responseStatus != 200 || fbResponse.responseObj == null) {
      fbFailed = true;
    } else {
      let responseObj = fbResponse.responseObj;
      if (typeof responseObj.authy_session_token !== "undefined" && responseObj.authy_session_token != null) {
        window.authy_session_token = responseObj.authy_session_token;
      }
      if (responseObj.healthcare_token && responseObj.full_dicom_store_paths) {
        savedAccessToken = (' ' + responseObj.healthcare_token).slice(1);
        user.getAccessToken = () => {return savedAccessToken};
        api.setAccessToken(savedAccessToken);

        UIModalService.hide();
        setIsLoggingIn(false);
        setLoginFailed(false);
        setIsLoggedIn(true);

        if (responseObj.full_dicom_store_paths.length > 0) {
          setDicomStores(responseObj.full_dicom_store_paths);
          console.log("store1: " + refStoreIndex.current);
          let tempDefaultDicomStorePath = responseObj.full_dicom_store_paths[refStoreIndex.current];
          window.loggedUserDicomStorePath = tempDefaultDicomStorePath;
          let idoRoot = "https://healthcare.googleapis.com/v1/" + tempDefaultDicomStorePath + "/dicomWeb";
          let idoParts = tempDefaultDicomStorePath.split('/');

          let hardData = {
            dataset: idoParts[5],
            dicomStore: idoParts[7],
            location: idoParts[3],
            project: idoParts[1],
            qidoRoot: idoRoot,
            wadoRoot: idoRoot,
            wadoUriRoot: idoRoot
          }; //ALEXREVENG ADDED THIS HERE
          const hardServers = GoogleCloudUtilServers.getServers(hardData, hardData.dicomStore);
          window.store.dispatch(setServers(hardServers));
        }
      } else {
        fbFailed = true;
      }
    }

    if (fbFailed) {
      setIsLoggingIn(false);
      setLoginFailed(true);
      setIsLoggedIn(false);
    }

    //UIModalService.hide();

    //window.shittyHistory.push("/temp");
    //window.shittyHistory.goBack();
  };

  const renderTableRow = ({ name, value, link }) => (
    <tr key={name} style={{ backgroundColor: 'transparent' }}>
      <td>{name}</td>
      <td>
        {link ? (
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {value}
          </a>
        ) : (
          value
        )}
      </td>
    </tr>
  );

  return (
    <div className="LoginContent" data-cy="login-modal">
      <div>{/*}</div>
      <div className="btn-group">
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="https://groups.google.com/forum/#!forum/cornerstone-platform"
        >
          {'Visit the forum'}
        </a>
        {` `}
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/OHIF/Viewers/issues/new/choose"
        >
          {'Report an issue'}
        </a>
        {` `}
        <a
          className="btn btn-default"
          target="_blank"
          rel="noopener noreferrer"
          href="http://ohif.org"
        >
          {'More details'}
        </a>
      </div>
      <div>{*/}</div>

      {!resettingPassword ? (
      <>
      <div style={{display: "flex", justifyContent: "center", marginBottom: "25px"}}>
        <div style={{display: "table", width: "350px"}}>
          <div style={{display: "inline", fontWeight: "bold"}}>Email</div>
          <div style={{height: "2px"}}></div>
          <div style={{display: "inline", width: "200px"}}><input id="emailField" type="text" autoComplete="true" className="form-control studylist-search" onKeyDown={onFormKeyPress} onChange={value => onTokenEdit(value.target.value)} style={{width: "100%"}} /></div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", marginBottom: "25px"}}>
        <div style={{display: "table", width: "350px"}}>
          <div style={{display: "inline", fontWeight: "bold"}}>Password</div>
          <div style={{height: "2px"}}></div>
          <div style={{display: "inline"}}><input id="passwordField" type="password" className="form-control studylist-search" onKeyDown={onFormKeyPress} onChange={value => onPasswordEdit(value.target.value)} style={{width: "100%"}} /></div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{display: "table", width: "350px"}}>
          <div style={{display: "inline", fontWeight: "bold"}}>Authy</div>
          <div style={{height: "2px"}}></div>
          <div style={{display: "inline"}}><input id="authyField" type="text" className="form-control studylist-search" onKeyDown={onFormKeyPress} onChange={value => onAuthyEdit(value.target.value)} style={{width: "100%"}} /></div>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{display: "table", width: "auto", marginTop: "17px"}}>
          {!isLoggingIn ? (
            <div style={{color: "red"}}>{loginFailed ? (<span>Unrecognized email or password</span>) : null}&nbsp;</div>
          ) : (
            <Icon name="circle-notch" className="spinClass" />
          )
          }
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{display: "table", width: "280px"}}>
          <div style={{height: "2px"}}></div>
          <button className="btn btn-primary" style={{margin: "20px 0px 0px 0px", width: "100%"}} onClick={() => onSubmitClicked()}>Log in</button>
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{display: "table", width: "280px"}}>
          <div style={{height: "2px"}}></div>
          <button className="btn" style={{margin: "20px 0px 0px 0px", width: "100%"}} onClick={() => onForgotClicked()}>Forgot password</button>
        </div>
      </div>
      </>
      ) : (
        <>

        {!sentEmail ? (
        <>
        <div style={{display: "flex", justifyContent: "center", marginBottom: "20px"}}>
          <div style={{display: "table", width: "350px"}}>
            <div style={{display: "inline", fontWeight: "bold"}}>Email</div>
            <div style={{height: "2px"}}></div>
            <div style={{display: "inline", width: "200px"}}><input id="emailField" type="text" autoComplete="true" className="form-control studylist-search" onKeyDown={onEmailKeyPress} onChange={value => onTokenEdit(value.target.value)} style={{width: "100%"}} /></div>
            <div style={{color: "red", marginTop: "5px"}}>{invalidEmail ? (<span>Please enter a valid email</span>) : null}&nbsp;</div>
          </div>
        </div>

        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{display: "table", width: "350px"}}>
            <div style={{height: "2px"}}></div>
            <button className="btn btn-primary" style={{margin: "20px 0px 0px 0px", width: "40%"}} onClick={() => onEmailClicked()}>Send reset email</button>
            <button className="btn" style={{margin: "20px 0px 0px 0px", width: "20%", float: "right"}} onClick={() => onCancelClicked()}>Cancel</button>
          </div>
        </div>
        </>
        ) : (
          <>

          <div style={{display: "flex", justifyContent: "center", marginBottom: "25px"}}>
            <div style={{display: "table", width: "auto"}}>
              {isCheckingEmail ? (
                <Icon name="circle-notch" className="spinClass" />
              ) :
              (
              <div style={{display: "inline", fontWeight: "bold", fontSize: "16px"}}>Sent password reset email if email was recognized</div>
              )
              }
            </div>
          </div>

          <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{display: "table", width: "auto"}}>
              <button className="btn" style={{margin: "20px 0px 0px 0px", width: "100%"}} onClick={() => onCancelClicked()}>Back to log in</button>
            </div>
          </div>

          </>
        )}

        </>
      )}

      <div>{/*}</div>
      <div>
        <h3>{'Version Information'}</h3>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>{'Name'}</th>
              <th>{'Value'}</th>
            </tr>
          </thead>
          <tbody>{itemsPreset().map(item => renderTableRow(item))}</tbody>
        </table>
      </div>
      <div>{*/}</div>
    </div>
  );
};

export { LoginContent };
export default LoginContent;
