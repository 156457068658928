import './StudyList.styl';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import TableSearchFilter from './TableSearchFilter.js';
import PropTypes from 'prop-types';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import { useTranslation } from 'react-i18next';
import { servicesManager, recoilRetivueLoggedIn, recoilRetivueStores, recoilRetivueStoreIndex } from '../../../../viewer/src/App';
import LoginContent from '../content/loginContent/LoginContent';
import * as GoogleCloudUtilServers from '../../../../viewer/src/googleCloud/utils/getServers';
import user from '../../../../core/src/user.js';
import api from "../../../../viewer/src/googleCloud/api/GoogleCloudApi";
import { redux } from '@ohif/core';

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

const getContentFromUseMediaValue = (
  displaySize,
  contentArrayMap,
  defaultContent
) => {
  const content =
    displaySize in contentArrayMap
      ? contentArrayMap[displaySize]
      : defaultContent;

  return content;
};
/**
 *
 *
 * @param {*} props
 * @returns
 */
function StudyList(props) {
  const {
    isLoading,
    hasError,
    studies,
    sort,
    onSort: handleSort,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
    noConnection
  } = props;
  const { t, ready: translationsAreReady } = useTranslation('StudyList');

  const { UIModalService } = servicesManager.services;
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(recoilRetivueLoggedIn);
  const refIsLoggedIn = useRef(isLoggedIn);
  const [dicomStores, setDicomStores] = useRecoilState(recoilRetivueStores);
  const refDicomStores = useRef(dicomStores);
  const [storeIndex, setStoreIndex] = useRecoilState(recoilRetivueStoreIndex);
  const refStoreIndex = useRef(storeIndex);
  const [refreshTrigger, setRefreshTrigger] = useState(true);
  const refRefreshTrigger = useRef(refreshTrigger);

  //*** Vacuous setters below necessary? For setinterval scope?

  useEffect(function() {
    refRefreshTrigger.current = refreshTrigger;
    refStoreIndex.current = storeIndex;
    if (refDicomStores.current.length > refStoreIndex.current) {
      console.log(refStoreIndex.current);
      refreshAccessToken(window.loggedUser).catch(function(err) {
        setIsLoggedIn(false);
      });
      //setStoreServer(refDicomStores.current[refStoreIndex.current]);
    }
  }, [storeIndex, refreshTrigger]);

  useEffect(function() {
    refDicomStores.current = dicomStores;
  }, [dicomStores]);

  //******

  useEffect(function() {
    if (!isLoading && !studies.length && noConnection) {
      setIsLoggedIn(false);
    }
  }, [noConnection, isLoading, studies]);

  function setStoreServer(full_dicom_store_paths) {
    const { setServers } = redux.actions;

    let tempDefaultDicomStorePath = full_dicom_store_paths[refStoreIndex.current];
    console.log("storeRef: " + refStoreIndex.current);
    window.loggedUserDicomStorePath = tempDefaultDicomStorePath;
    let idoRoot = "https://healthcare.googleapis.com/v1/" + tempDefaultDicomStorePath + "/dicomWeb";
    let idoParts = tempDefaultDicomStorePath.split('/');

    //if (window.loggedUserDicomStorePath !== tempDefaultDicomStorePath) {
      let hardData = {
        dataset: idoParts[5],
        dicomStore: idoParts[7],
        location: idoParts[3],
        project: idoParts[1],
        qidoRoot: idoRoot,
        wadoRoot: idoRoot,
        wadoUriRoot: idoRoot
      }; //ALEXREVENG ADDED THIS HERE
      const hardServers = GoogleCloudUtilServers.getServers(hardData, hardData.dicomStore);
      window.store.dispatch(setServers(hardServers));
      window.loggedUserStoreIndex = refStoreIndex.current;
    //}
  }

  async function refreshAccessToken(currentUser) {
    return new Promise(function(resolve, reject) {
      const { setServers } = redux.actions;

      let postObj = {
        user_token: currentUser.accessToken,
        user_uid: currentUser.uid,
        authy_session_token: window.authy_session_token
      };

      let tokenReq = new XMLHttpRequest();
      tokenReq.open("POST", window.config.firebase.tokenGenerator);
      tokenReq.setRequestHeader("Content-Type", "application/json");
      tokenReq.onerror = function() {
        setIsLoggedIn(false);
        reject();
      };
      tokenReq.onreadystatechange = function() {
        if (tokenReq.readyState === XMLHttpRequest.DONE) {
          let responseObj = null;
          try {
          responseObj = JSON.parse(tokenReq.responseText);
          } catch (error) {
            setIsLoggedIn(false);
            reject();
            return;
          }
          if (typeof responseObj.authy_session_token !== "undefined" && responseObj.authy_session_token != null) {
            window.authy_session_token = responseObj.authy_session_token;
          }
          if (responseObj.healthcare_token && responseObj.full_dicom_store_paths) {
            let savedAccessToken = (' ' + responseObj.healthcare_token).slice(1);
            user.getAccessToken = () => {return savedAccessToken};
            api.setAccessToken(savedAccessToken);

            if (responseObj.full_dicom_store_paths.length > 0) {
              setDicomStores(responseObj.full_dicom_store_paths);
              if (refStoreIndex.current !== window.loggedUserStoreIndex) {
                setStoreServer(responseObj.full_dicom_store_paths);
              }
            }
          } else {
            //Failed
            //REJECT???
            setIsLoggedIn(false);
            reject();
            return;
          }

          resolve();
        }
      };
      tokenReq.send(JSON.stringify(postObj));
    });
  }

  useEffect(function() {
    refIsLoggedIn.current = isLoggedIn;
    if (!isLoggedIn) {
      const timer = setTimeout(function() {
        UIModalService.show({
          content: LoginContent,
          title: "Log in to RetiVue Cloud",
          shouldCloseOnEsc: false,
          closeButton: false
        });
      }, 200);
      return function() {
        clearTimeout(timer);
      }
    }
  }, [isLoggedIn]);

  useEffect(function() {
    /*
    const tokenLoop = async function() {

      if (refIsLoggedIn.current) {
        //await refreshAccessToken(window.loggedUser);

      }
      timerId = setTimeout(tokenLoop, 1000 * 60 * 3 / 3 / 60 * 10);
    };
    tokenLoop();
    */
    let timerId = null;
    timerId = setInterval(() => {
      console.log("fresh" + refRefreshTrigger.current);
      if (refIsLoggedIn.current) {
        setRefreshTrigger(!refRefreshTrigger.current);
      }
    }, 1000 * 60 * 3);

    return () => {
      if (timerId != null) {
        clearInterval(timerId);
      }
    };
  }, []);

  const largeTableMeta = [
    {
      displayText: t('PatientName'),
      fieldName: 'PatientName',
      inputType: 'text',
      size: 330,
    },
    {
      displayText: t('MRN'),
      fieldName: 'PatientID',
      inputType: 'text',
      size: 378,
    },
    {
      displayText: t('AccessionNumber'),
      fieldName: 'AccessionNumber',
      inputType: 'text',
      size: 180,
    },
    {
      displayText: t('StudyDate'),
      fieldName: 'StudyDate',
      inputType: 'date-range',
      size: 300,
    },
    {
      displayText: t('Modality'),
      fieldName: 'modalities',
      inputType: 'text',
      size: 114,
    },
    {
      displayText: t('StudyDescription'),
      fieldName: 'StudyDescription',
      inputType: 'text',
      size: 335,
    },
  ];

  const mediumTableMeta = [
    {
      displayText: `${t('PatientName')} / ${t('MRN')}`,
      fieldName: 'patientNameOrId',
      inputType: 'text',
      size: 250,
    },
    {
      displayText: t('Description'),
      fieldName: 'accessionOrModalityOrDescription',
      inputType: 'text',
      size: 350,
    },
    {
      displayText: t('StudyDate'),
      fieldName: 'StudyDate',
      inputType: 'date-range',
      size: 300,
    },
  ];

  const smallTableMeta = [
    {
      displayText: t('Search'),
      fieldName: 'allFields',
      inputType: 'text',
      size: 100,
    },
  ];

  const tableMeta = getContentFromUseMediaValue(
    displaySize,
    { large: largeTableMeta, medium: mediumTableMeta, small: smallTableMeta },
    smallTableMeta
  );

  const totalSize = tableMeta
    .map(field => field.size)
    .reduce((prev, next) => prev + next);

  return translationsAreReady ? (
    <table className="table table--striped table--hoverable">
      <colgroup>
        {tableMeta.map((field, i) => {
          const size = field.size;
          const percentWidth = (size / totalSize) * 100.0;

          return <col key={i} style={{ width: `${percentWidth}%` }} />;
        })}
      </colgroup>
      <thead className="table-head">
        <tr className="filters">
          <TableSearchFilter
            meta={tableMeta}
            values={filterValues}
            onSort={handleSort}
            onValueChange={handleFilterChange}
            sortFieldName={sort.fieldName}
            sortDirection={sort.direction}
            studyListDateFilterNumDays={studyListDateFilterNumDays}
          />
        </tr>
      </thead>
      <tbody className="table-body" data-cy="study-list-results">
        {/* I'm not in love with this approach, but it's the quickest way for now
         *
         * - Display different content based on loading, empty, results state
         *
         * This is not ideal because it create a jump in focus. For loading especially,
         * We should keep our current results visible while we load the new ones.
         */}
        {/* LOADING */}
        {isLoading && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <StudyListLoadingText />
            </td>
          </tr>
        )}
        {!isLoading && hasError && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">
                {t('There was an error fetching studies')}
              </div>
            </td>
          </tr>
        )}
        {/* EMPTY */}
        {!isLoading && !studies.length && !noConnection && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">{t('No matching results')}</div>
            </td>
          </tr>
        )}
        {!isLoading && !studies.length && noConnection && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">{t("Log in to view studies")}</div>
            </td>
          </tr>
        )}
        {!isLoading &&
          studies.map((study, index) => (
            <TableRow
              key={`${study.StudyInstanceUID}-${index}`}
              onClick={StudyInstanceUID => handleSelectItem(StudyInstanceUID)}
              AccessionNumber={study.AccessionNumber || ''}
              modalities={study.modalities}
              PatientID={study.PatientID || ''}
              PatientName={study.PatientName || ''}
              StudyDate={study.StudyDate}
              StudyDescription={study.StudyDescription || ''}
              StudyInstanceUID={study.StudyInstanceUID}
              displaySize={displaySize}
            />
          ))}
      </tbody>
    </table>
  ) : null;
}

StudyList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  studies: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    PatientName: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    AccessionNumber: PropTypes.string.isRequired,
    StudyDate: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    StudyDescription: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    accessionOrModalityOrDescription: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    studyDateTo: PropTypes.any,
    studyDateFrom: PropTypes.any,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
};

StudyList.defaultProps = {};

function TableRow(props) {
  const {
    AccessionNumber,
    isHighlighted,
    modalities,
    PatientID,
    PatientName,
    StudyDate,
    StudyDescription,
    StudyInstanceUID,
    onClick: handleClick,
    displaySize,
  } = props;

  const { t } = useTranslation('StudyList');

  let patientNameTable = "OP";

  if (PatientName) {
    patientNameTable = PatientName;
  }

  if (patientNameTable.includes('^')) {
    let nameParse = patientNameTable.split('^');
    if (nameParse.length >= 2 && nameParse[0].length > 0 && nameParse[1].length > 0) {
      patientNameTable = nameParse[0] + ", " + nameParse[1];
    }
  }

  const largeRowTemplate = (
    <tr
      onClick={() => handleClick(StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !patientNameTable })}>
        {patientNameTable || `(${t('Empty')})`}
      </td>
      <td>{PatientID}</td>
      <td>{AccessionNumber}</td>
      <td>{StudyDate}</td>
      <td className={classNames({ 'empty-value': !modalities })}>
        {modalities || "OP"}
      </td>
      <td>{StudyDescription}</td>
    </tr>
  );

  const mediumRowTemplate = (
    <tr
      onClick={() => handleClick(StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !patientNameTable })}>
        {patientNameTable || `(${t('Empty')})`}
        <div style={{ color: '#60656f' }}>{PatientID}</div>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* DESCRIPTION */}
          <div
            className="hide-xs"
            style={{
              whiteSpace: 'pre-wrap',
              flexGrow: 1,
            }}
          >
            {StudyDescription}
          </div>

          {/* MODALITY & ACCESSION */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
            }}
          >
            <div
              className={classNames({
                modalities: modalities,
                'empty-value': !modalities,
              })}
              aria-label={modalities}
              title={modalities}
            >
              {modalities || "OP"}
            </div>
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              aria-label={AccessionNumber}
              title={AccessionNumber}
            >
              {AccessionNumber}
            </div>
          </div>
        </div>
      </td>
      {/* DATE */}
      <td style={{ textAlign: 'center' }}>{StudyDate}</td>
    </tr>
  );

  const smallRowTemplate = (
    <tr
      onClick={() => handleClick(StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* NAME AND ID */}
          <div
            className={classNames({ 'empty-value': !patientNameTable })}
            style={{ width: '150px', minWidth: '150px' }}
          >
            <div style={{ fontWeight: 500, paddingTop: '3px' }}>
              {patientNameTable || `(${t('Empty')})`}
            </div>
            <div style={{ color: '#60656f' }}>{PatientID}</div>
          </div>

          {/* DESCRIPTION */}
          <div
            className="hide-xs"
            style={{
              whiteSpace: 'pre-wrap',
              flexGrow: 1,
              paddingLeft: '35px',
            }}
          >
            {StudyDescription}
          </div>

          {/* MODALITY & DATE */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
            }}
          >
            <div
              className={classNames({
                modalities: modalities,
                'empty-value': !modalities,
              })}
              aria-label={modalities}
              title={modalities}
            >
              {modalities || "OP"}
            </div>
            <div>{StudyDate}</div>
          </div>
        </div>
      </td>
    </tr>
  );

  const rowTemplate = getContentFromUseMediaValue(
    displaySize,
    {
      large: largeRowTemplate,
      medium: mediumRowTemplate,
      small: smallRowTemplate,
    },
    smallRowTemplate
  );

  return rowTemplate;
}

TableRow.propTypes = {
  AccessionNumber: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  modalities: PropTypes.string,
  PatientID: PropTypes.string.isRequired,
  PatientName: PropTypes.string.isRequired,
  StudyDate: PropTypes.string.isRequired,
  StudyDescription: PropTypes.string.isRequired,
  StudyInstanceUID: PropTypes.string.isRequired,
  displaySize: PropTypes.string,
};

TableRow.defaultProps = {
  isHighlighted: false,
};

export { StudyList };
